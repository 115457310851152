import React, { useCallback, useEffect, useRef, useState } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import Text from './Text';
import ArrowDownIcon from 'assets/icons/arrowDown.svg';
import ArrowUpIcon from 'assets/icons/arrowUp.svg';
import { mobile, useQuery } from 'styles/breakpoints';
import { DynamicImage } from './images/DynamicImage';
import Tracking from 'utils/tracking';

interface Item {
  question: string;
  answer: string[];
}

interface PeopleAskUsProps {
  title: string;
  list: Item[];
  icon: string;
  iconText: string;
  bgColor?: string;
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  text-align: left;
`;

const QuestionContainer = styled.div<{ expanded: boolean }>`
  overflow: ${({ expanded }) => (expanded ? 'auto' : 'hidden')};
  ::-webkit-scrollbar {
    width: 0;
  }
  transition: max-height 0.6s ease;
  border: 1px solid rgba(33, 33, 33, 0.08);
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.light0};
  cursor: pointer;
  padding: 1.5rem 1.5rem 0;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled(Text)`
  font-weight: 700;
  text-align: center;
  color: #28293d;
  text-align: left;
  margin-top: 1rem;
  font-size: 1.75rem;
  @media ${mobile} {
    font-weight: 700;
    font-size: 1.25rem;
  }
`;

const Question = styled(Text).attrs({
  type: 'tabActive',
})<{ expanded?: boolean }>`
  color: ${({ expanded }) => (expanded ? '#804DA1' : 'dark80')};
  transition: color 0.2s ease;
  /* fixes large text on ios issue */
  -webkit-text-size-adjust: none;
`;

const List = styled.div`
  margin-top: 2rem;

  div:nth-child(1n):not(:last-child) {
    margin-bottom: 1.5rem;
  }

  @media ${mobile} {
    margin-top: 2rem;
  }
`;

const AnswerContainer = styled.div<{ expanded: boolean }>`
  max-height: ${({ expanded }) => (expanded ? 700 : 0)}px;
  transition: max-height 0.4s ease;
  margin-top: 1.06rem;
  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: auto;

  /* hide scrollbar for Chrom, Safari */
  ::-webkit-scrollbar {
    width: 0;
    display: none;
  }
`;

const ArrowDown = styled(ArrowDownIcon)`
  max-width: 1.5rem;
  min-width: 1.5rem;
`;

const ArrowRight = styled(ArrowUpIcon)`
  max-width: 1.5rem;
  min-width: 1.5rem;
`;

const Answer = styled(Text)<{ expanded: boolean }>`
  padding-bottom: 1.5rem;
  -webkit-text-size-adjust: 100%;
  max-width: 704px;
`;

const Icon = styled(DynamicImage)`
  max-width: 52.45px;
  height: 49px;
`;

const IconText = styled.p`
  margin-left: 24px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 26px;
  color: #28293d;
`;

const IconContainer = styled.div`
  display: flex;
`;

const PeopleAskUs: FC<PeopleAskUsProps> = ({
  title,
  list,
  icon,
  iconText,
  bgColor,
}) => {
  const [expandedQuestions, setExpandedQuestions] = useState<number[]>([]);
  const childRef = useRef<HTMLDivElement>(null);
  const { isMobile } = useQuery();
  const quiz = new URLSearchParams(location.search).get('qz') ?? 'main';

  const handleQuestionClick = (index: number, item: Item) => {
    handleClick(index);
    Tracking.logEvent({
      event: 'FAQ-clicks',
      funnel: quiz,
      widget: item.question,
    });
  };

  const handleClick = useCallback(
    (index: number) => {
      if (expandedQuestions.includes(index)) {
        setExpandedQuestions(prevState => [
          ...prevState.filter(item => item !== index),
        ]);
        return;
      }
      setExpandedQuestions(prevState => [...prevState, index]);
    },
    [expandedQuestions],
  );

  useEffect(() => {
    if (!childRef.current) return;
  }, [childRef, expandedQuestions]);

  const renderItem = (item: Item, index: number) => {
    const key = `ask-us-question-${index}`;
    const expanded = expandedQuestions.includes(index);

    return (
      <QuestionContainer
        key={key}
        onClick={() => handleQuestionClick(index, item)}
        expanded={expanded}
      >
        <TitleContainer>
          {expanded ? <ArrowRight /> : <ArrowDown />}
          <Question expanded={expanded}>{item.question}</Question>
        </TitleContainer>

        <AnswerContainer expanded={expanded}>
          <div ref={childRef}>
            {item.answer.map(answer => (
              <Answer
                type="bodyM"
                color="dark80"
                expanded={expanded}
                key={answer}
                dangerouslySetInnerHTML={{ __html: answer }}
              />
            ))}
          </div>
        </AnswerContainer>
      </QuestionContainer>
    );
  };

  return (
    <Container>
      <IconContainer>
        <Icon src={icon} alt="" />
        <IconText>{iconText}</IconText>
      </IconContainer>
      <Title>{title}</Title>
      <List>{list.map(renderItem)}</List>
    </Container>
  );
};

export default React.memo(PeopleAskUs);
